<template>

  <div>
        <b-card

      title="Get row form"
    >

      <validation-observer ref="simpleRules">

        <b-form @submit.prevent>
          <b-row>


            <b-col cols="3">
              <b-form-group
                label="Big Query DataSet"
                label-for="v-bigQueryDataSet"
              >
                <validation-provider
                  #default="{ errors }"
                  name="DataSet"
                  rules="required|alpha_dash"
                >
                  <b-form-input
                    id="v-bigQueryDataSet"
                    v-model="criterias.dataset"
                    placeholder="Big Query DataSet"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                  <span class="text-danger"> {{ errors[0] }} </span>

                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Big Query Table"
                label-for="v-bigQueryTable"
              >
                <validation-provider
                  #default="{ errors }"
                  name="bigQueryTable"
                  rules="required|alpha_dash"
                >
                  <b-form-input
                    id="v-bigQueryTable"
                    v-model="criterias.table"
                    placeholder="Big Query Table"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                  <span class="text-danger"> {{ errors[0] }} </span>

                </validation-provider>

              </b-form-group>
            </b-col>
            
            <b-col cols="3">
              <b-form-group
                label="Big Query Cloumn"
                label-for="v-bigQueryCloumn"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cloumn Name"
                  rules="required|alpha_dash"
                >
                  <b-form-input
                    id="v-bigQueryCloumn"
                    v-model="criterias.whereColumn"
                    placeholder="Big Query Column"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                  <span class="text-danger"> {{ errors[0] }} </span>

                </validation-provider>

              </b-form-group>
              
            </b-col>


            <b-col cols="3">
              <b-form-group
                label="Cloumn Value"
                label-for="v-cloumnValue"
              >
                <validation-provider
                  #default="{ errors }"
                  name="value"
                  rules="required"
                >
                  <b-form-input
                    id="v-documentCloumn"
                    v-model="criterias.value"
                    placeholder="Cloumn value"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="Order BY"
                label-for="v-cloumnValue"
              >
                <validation-provider
                  #default="{ errors }"
                  name="orderBy"
                >
                  <b-form-input
                    id="v-orderBy"
                    v-model="criterias.orderBy"
                    placeholder="ORDER BY"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="Big Query Cloumn"
                label-for="v-bigQueryCloumn"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cloumns"
                  rules="required"
                >
                  <b-form-input
                    id="v-bigQueryCloumn"
                    v-model="criterias.columns"
                    placeholder="Big Query Columns"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                  <span class="text-danger"> {{ errors[0] }} </span>

                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="loading"
                @click="getRow"
              >
                Get row
              </b-button>

            </b-col>

        

          </b-row>

        </b-form>

      </validation-observer>

    </b-card>
    <b-card
      title="Reults"
      >

        <b-alert
          v-if="error"
          variant="danger"
          show
          class="mt-2"
        >

          <div class="alert-body">
            <span><strong>Error </strong> {{ error }}</span>

          </div>

        </b-alert>

        <div v-if="result.queryString">
          <pre class="language-markup mb-2 pt-1">
          SQL Query : {{ result.queryString }} 
        </pre>

         <pre class="language-markup">

          
                <div v-html="result.items"></div>
           
  
         </pre>
        </div>

    </b-card>  
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BAlert, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  required,
  alpha_num,
  alpha_dash,
} from 'vee-validate/dist/rules'

extend('required', required)
extend('alpha_num', alpha_num)
extend('alpha_dash', alpha_dash)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BAlert,
    BForm,
    BButton,
    BCard,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      criterias: {},
      result: {},
      error: null,
      loading: false,
    }
  },
  mounted() {

  },
  methods: {

    getRow() {

      this.result = {}  
      this.error = null
      
      this.$refs.simpleRules.validate().then(valid => {
        if (valid) {
          this.loading = true

          this.$store.dispatch('bigQuery/getRows', this.criterias )
            .then((result) => {
          
              this.result = result.data
            },
            error => {
              this.error = error.response?.data?.message || error?.message
              this.showToast('Error', error.response?.data?.message || error?.message|| 'Error while saving', 'danger')
            }).finally(() => {
              this.loading = false
            })
        } else {
          this.showToast('Form', 'Check your inputs please', 'warning')
        }
      }, error => {
        console.log(error)
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
